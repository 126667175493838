/* eslint-disable no-console */
import * as Sentry from '@sentry/node'
import * as SentryReact from '@sentry/react'
import actions from 'actions/authentication'
import mobileActions from 'actions/mobileApp'
import { createReferrerAction } from 'actions/referrer'
import '@christies/components/built/stencil/dist/christies-design-system-library/christies-design-system-library.css'
import 'assets/styles/main.scss'
import FurnitureScripts from 'components/Furniture'
import { Footer, FooterSize } from 'components/Furniture/Footer'
import { Header, HeaderSize } from 'components/Furniture/Header'
import { MetaTags } from 'components/MetaTags'
import Modal from 'components/Modal'
import TranslationContext from 'components/Translations/TranslationContext'
import { DEVICE_OPTIONS, ROUTE } from 'configuration'
import environment, { isProductionBuild } from 'configuration/env'
import { initSentry } from 'configuration/sentry'
import { LANGUAGE_CONFIG, SupportedLanguages } from 'configuration/translations'
import { StatusPageShell } from 'containers/StatusPageShell'
import { IsomorphicCookieProvider } from 'infrastructure/isomorphic-cookies'
import requestContext from 'infrastructure/request-context'
import { ISettingsType } from 'models/settings'
import App, { AppContext, AppProps } from 'next/app'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { IAppState } from 'reducers'
import { END } from 'redux-saga'
import sessionProvider, { StringOrNotSet, UserSession } from 'session'
import { ILabel } from 'shared/labels'
import { SagaStore, wrapper } from 'store'
import { ITranslation } from 'translations'
import { logService } from 'utils/logService'
import { getClientIdFromToken } from 'utils/token-decoder'
import { ChrLiveChatModal } from '../components/ChrLiveChatModal'
import TranslationsService from '../services/translations'
import { getRegion, isChineseRegion } from '../utils/getRegion'
import { Store } from 'redux'
import { NextPageContext } from 'next'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { reactPlugin } from 'configuration/appInsights'
import { getUserLanguage } from 'utils/urls'

declare global {
  interface Window {
    AnalyticsDataLayer?: Window['AnalyticsDataLayer']
    Cypress: any
    IsMobileApp: boolean
  }
}

initSentry()

let clientTranslationCache: ILabel[] = []
let settingsCache: ISettingsType

const pageData = {
  status: 500,
  title: 'This page isn’t working',
  subtitle:
    'The server encountered an internal error or misconfiguration and was unable to complete your request. Our apologies.',
}

export interface CustomNextPageContext extends NextPageContext {
  store: Store
}

export interface CustomAppContext extends AppContext {
  ctx: CustomNextPageContext
}

export type AccountCreationPageProps = {
  useMaximalHeader: boolean
  useMaximalFooter: boolean
  labels: ILabel[]
  settings: ISettingsType
  statusCode: number
  language: string
  useDarkMode: boolean
  buildEnvironment: string
  buildId: string
  buildType: string
}

export type AccountCreationProps = {
  err?: any
  pageProps: AccountCreationPageProps
}

type AccountCreationAppProps = AppProps & AccountCreationProps

type FurnitureState = {
  headerSize: HeaderSize
  footerSize: FooterSize
  loadError: boolean
}

function AccountCreationApp({ Component, pageProps, err }: AccountCreationAppProps) {
  if (process.env.NEXT_IS_SERVER !== 'true') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    requestContext.set(useStore())
  }
  const { buildEnvironment, buildId, buildType, labels, useDarkMode } = pageProps

  const useMaximalHeader = pageProps?.useMaximalHeader ?? false
  const useMaximalFooter = pageProps?.useMaximalFooter ?? true

  const [hideHeader, setHideHeader] = useState<boolean>(false)
  const dispatch = useDispatch()
  const router = useRouter()
  const [furnitureState, setFurnitureState] = useState<FurnitureState>({
    headerSize: HeaderSize.Minimal,
    footerSize: FooterSize.Minimal,
    loadError: false,
  })

  const hasAuthenticationError = useSelector<IAppState>((state) => !!state?.authentication?.error)
  const userLoggedIn = useSelector<IAppState>((state) => !!state?.account.clientGuid) as boolean
  const isMobileApp = useSelector<IAppState>((state) => state.authentication.isMobile) as boolean
  const serverError = useSelector<IAppState>(() => undefined) as string
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setModalIsOpen(typeof serverError !== 'undefined')
  }, [serverError])

  useEffect(() => {
    shouldBeHidden(router.pathname)
  }, [router.pathname])

  const shouldBeHidden = (path: string): void => {
    const hideOnPaths = [
      ROUTE.SIGN_UP,
      ROUTE.CONFIRM_DETAILS,
      ROUTE.ID_VERIFICATION,
      ROUTE.KYC_PERSONAL_DETAILS,
      ROUTE.SECURITY_QUESTIONS,
    ]
    setHideHeader(hideOnPaths.includes(path))
  }

  const onFurnitureLoadError = (url: string): any => {
    console.log('Could not load header/footer script', url)
    Sentry.captureEvent({
      message: 'Could not load header/footer',
      level: Sentry.Severity.Error,
      tags: {
        where: 'header-footer',
        script: url,
        whichend: process.env.NEXT_IS_SERVER === 'true' ? 'back' : 'front',
        region: getRegion(),
      },
    })

    setFurnitureState((state) => ({
      ...state,
      loadError: true,
    }))
  }

  useEffect(() => {
    const handleRouteChange = () => {
      if (hasAuthenticationError) {
        dispatch(actions.resetErrorAction())
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [dispatch, hasAuthenticationError, router.events])

  useEffect(() => {
    const scrollTop = () => {
      window.scrollTo(0, 0)
    }

    router.events.on('routeChangeComplete', scrollTop)

    return () => {
      router.events.off('routeChangeComplete', scrollTop)
    }
  }, [router.events])

  useEffect(() => {
    setFurnitureState((state) => ({
      ...state,
      headerSize: useMaximalHeader && !state.loadError ? HeaderSize.Maximal : HeaderSize.Minimal,
      footerSize: useMaximalFooter && !state.loadError ? FooterSize.Maximal : FooterSize.Minimal,
    }))
  }, [useMaximalHeader, useMaximalFooter, furnitureState.loadError])

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles?.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles)
    }

    sessionProvider.setCookieProvider(new IsomorphicCookieProvider())
  }, [])

  useEffect(() => {
    if (typeof s !== 'undefined') {
      const handleLoad = () => dispatch(createReferrerAction(s.getPreviousValue(s.pageURL)))

      window.addEventListener('load', handleLoad)

      return () => {
        window.removeEventListener('load', handleLoad)
      }
    } else {
      return undefined
    }
  }, [dispatch])

  const translationContextValue = useMemo(
    () => ({ translations: labels as Array<ITranslation>, isMobile: isMobileApp }),
    [isMobileApp, labels]
  )

  return (
    <TranslationContext.Provider value={translationContextValue}>
      {!isMobileApp && (useMaximalHeader || useMaximalFooter) && (
        <FurnitureScripts onError={onFurnitureLoadError} language={pageProps.language} />
      )}
      {/*
       * onetrust cookie consent variables ~ shows cookie banner
       * only show on web, not in webview
       */}
      {/* eslint-disable-next-line @next/next/no-before-interactive-script-outside-document */}
      <Script id="ot-cookie-consent-vars" strategy="beforeInteractive">
        {`window.IsMobileApp=${isMobileApp}; window.EnableCookieBanner=${!isMobileApp}`}
      </Script>

      {typeof window === 'undefined' ||
        (typeof window !== 'undefined' && !window.Cypress && (
          <Script type="text/javascript" src={environment.adobeContainerUrl} async />
        ))}
      {!isMobileApp && (
        <Header
          userLoggedIn={userLoggedIn}
          hideWhenLoggedIn={hideHeader}
          size={furnitureState.headerSize}
        />
      )}
      <main
        role="main"
        className={`main ${useDarkMode ? 'dark' : ''}`}
        data-environment={buildEnvironment}
        data-build={buildType}
        data-buildid={buildId}
      >
        <SentryReact.ErrorBoundary fallback={<StatusPageShell pageData={pageData} />}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <MetaTags />
            <Component {...pageProps} err={err} />
          </AppInsightsContext.Provider>
        </SentryReact.ErrorBoundary>
      </main>
      {!isMobileApp && <Footer size={furnitureState.footerSize} />}
      <Modal
        isOpen={modalIsOpen && typeof serverError !== 'undefined'}
        closeModal={() => setModalIsOpen(false)}
        heading={`.server_error_header`}
      >
        {serverError}
      </Modal>
      {!isChineseRegion() && <ChrLiveChatModal />}
    </TranslationContext.Provider>
  )
}

AccountCreationApp.getInitialProps = async (appContext: CustomAppContext) => {
  const { ctx } = appContext
  const { req, res, query } = ctx
  requestContext.set(ctx.store, req, res)
  const cookieProvider = new IsomorphicCookieProvider(req, res)
  // Set up the cookie provider
  sessionProvider.setCookieProvider(cookieProvider)

  // Temporary solution until header v2 is ready
  const userSession: UserSession = sessionProvider.getUserSession()
  const webClientId: StringOrNotSet = userSession.clientId
  const accessTokenClientId: string | undefined = getClientIdFromToken(
    userSession.accessToken || ''
  )

  if (webClientId && accessTokenClientId && webClientId !== accessTokenClientId) {
    sessionProvider.clearUserSession()
    sessionProvider.clearApplicationSession()

    if (res) {
      if (requestContext.store) {
        requestContext.store.dispatch({ type: 'CHR/RESET' })
      }
      res.setHeader('Location', ROUTE.HOME)
      res.statusCode = 302
      res.end()
    } else {
      window.location.assign(ROUTE.HOME)
    }

    return
  }

  let isMobileApp = false

  if (req) {
    if (query.ReturnURL) {
      ctx.store.dispatch(createReferrerAction(query.ReturnURL))
    }

    if (req?.headers['user-agent']?.includes(DEVICE_OPTIONS.mobile_useragent)) {
      ctx.store.dispatch(mobileActions.setMobileFlag())

      isMobileApp = true

      Sentry.addBreadcrumb({
        category: 'Account Creation',
        message: `User has been detected as a MOBILE APP user with user agent ${req?.headers['user-agent']}`,
        level: Sentry.Severity.Info,
      })
    } else {
      Sentry.addBreadcrumb({
        category: 'Account Creation',
        message: `User has been detected as a BROWSER user with user agent ${req?.headers['user-agent']}`,
        level: Sentry.Severity.Info,
      })
    }
  } else {
    const state = ctx.store.getState() as IAppState

    isMobileApp = state.authentication.isMobile
  }

  // Detect if on mobile device
  logService.setDeviceType(isMobileApp)

  // Calls page's `getInitialProps` so  all page actions dispatch, and fills `appProps.pageProps`
  let appProps = await App.getInitialProps(appContext)

  if (appProps.pageProps.isRedirecting) return

  // Stop the sagas if on server after getInitialProps has run
  // to ensure that we can retrieve the results of promises
  if (req) {
    ctx.store.dispatch(END)
    await (ctx.store as SagaStore).sagaTask.toPromise()
  }

  // Construct our props
  const { pageProps } = appProps

  const statusCode = pageProps.statusCode || 200

  const queryLang = query[LANGUAGE_CONFIG.paramName]?.toString() as SupportedLanguages
  const cookieLang = cookieProvider.get(LANGUAGE_CONFIG.cookieName) as SupportedLanguages
  console.info(`Query = ${queryLang} // Cookie = ${cookieLang}`)

  Sentry.addBreadcrumb({
    category: 'Account Creation',
    message: `Query language: ${queryLang} Cookie language: ${cookieLang}`,
    level: Sentry.Severity.Info,
  })

  const language = getUserLanguage(queryLang, cookieLang)

  ctx.store.dispatch(actions.setLanguageAction(language))

  // set cookie
  if (process.env.NEXT_PHASE !== 'phase-production-build') {
    cookieProvider.set(LANGUAGE_CONFIG.cookieName, language, LANGUAGE_CONFIG.cookieOptions)
    console.info(`Language is set to: ${language}`)

    Sentry.addBreadcrumb({
      category: 'Account Creation',
      message: `Language is set to: ${language}`,
      level: Sentry.Severity.Info,
    })
  } else {
    console.log('Building production release - skipping setting language cookies')
  }
  const needsSettings = pageProps.needsSettings
  const preloadLabelPrefixes: string[] = (pageProps.preloadLabelPrefixes as string[]) || []

  delete pageProps.preloadLabelPrefixes

  appProps = {
    ...appProps,
    pageProps: {
      ...pageProps,
      statusCode,
      language,
      labels: [],
      buildEnvironment: environment.name,
      buildId: environment.buildId,
      buildType: isProductionBuild() ? 'production' : 'test',
    },
  }

  try {
    if (req) {
      if (statusCode === 200) {
        if (preloadLabelPrefixes.length || needsSettings) {
          const { dictionary, settings } = await TranslationsService.get(
            language as SupportedLanguages
          )
          if (needsSettings) {
            appProps.pageProps.settings = settings
          }
          if (preloadLabelPrefixes.length) {
            preloadLabelPrefixes.push('meta', 'links', 'live_chat')
            appProps.pageProps.labels = dictionary.filter((item) =>
              preloadLabelPrefixes.some((prefix) => {
                return item.key.startsWith(prefix)
              })
            )
          } else {
            appProps.pageProps.labels = dictionary
          }
        }
      }
    } else {
      if (clientTranslationCache.length === 0 || settingsCache === undefined) {
        console.info('Client-side settings/translation cache empty - requesting')
        const { dictionary, settings } = await TranslationsService.get(
          language as SupportedLanguages
        )
        clientTranslationCache = dictionary
        settingsCache = settings
      } else {
        console.info('Client-side translations/settings caches full - no need to request')
      }
      appProps.pageProps.labels = clientTranslationCache
      appProps.pageProps.settings = settingsCache
    }
  } catch (e) {
    Sentry.captureException(e, {
      tags: { end: process.env.NEXT_IS_SERVER === 'true' ? 'back' : 'front' },
    })
    appProps.pageProps.statusCode = 500
  }

  if (res) {
    res.setHeader('X-Frame-Options', 'SAMEORIGIN')
  }

  return appProps as AccountCreationAppProps
}

export default wrapper.withRedux(AccountCreationApp)
