import notFound from '../../assets/images/icons/404.svg'
import bid from '../../assets/images/icons/bid.svg'
import chevron from '../../assets/images/icons/chevron.svg'
import cross from '../../assets/images/icons/cross.svg'
import crossClear from '../../assets/images/icons/cross-clear.svg'
import circleCross from '../../assets/images/icons/circle-cross.svg'
import diamond from '../../assets/images/icons/diamond.svg'
import edit from '../../assets/images/icons/edit.svg'
import fileIndicator from '../../assets/images/icons/file-indicator.svg'
import info from '../../assets/images/icons/info.svg'
import infoLarge from '../../assets/images/icons/info-large.svg'
import loading from '../../assets/images/icons/loading.svg'
import minus from '../../assets/images/icons/minus.svg'
import paperclip from '../../assets/images/icons/paperclip.svg'
import pdfIndicator from '../../assets/images/icons/pdf-indicator.svg'
import plus from '../../assets/images/icons/plus.svg'
import search from '../../assets/images/icons/search.svg'
import tick from '../../assets/images/icons/tick.svg'
import tickBig from '../../assets/images/icons/tick-big.svg'
import user from '../../assets/images/icons/user.svg'
import verified from '../../assets/images/icons/verified.svg'
import logo from '../../assets/images/logo/christies.svg'
import logoChinese from '../../assets/images/logo/christies-chinese.svg'

const icons = {
  notFound,
  bid,
  chevron,
  cross,
  crossClear,
  circleCross,
  diamond,
  edit,
  fileIndicator,
  info,
  infoLarge,
  loading,
  minus,
  paperclip,
  pdfIndicator,
  plus,
  search,
  tick,
  tickBig,
  user,
  verified,
  logo,
  logoChinese,
}

export type Icons = keyof typeof icons

export default icons
